<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              {{ isBookingCenter() ? "登記預約中心" : "預約管理" }}
            </b-breadcrumb-item>

            <b-breadcrumb-item v-if="!isBookingCenter()" :to="{ name: 'BookingSelectList' }">
              預約列表
            </b-breadcrumb-item>

            <b-breadcrumb-item active>
              {{ bookingPreset.name }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="d-flex
          flex-column flex-xl-row
          align-items-start align-items-xl-center
          justify-content-between
          mb-4 mb-xl-2">
        <h4 class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold">{{ bookingPreset.name }}</h4>
        <div class="row-searchbar d-flex mb-2 mb-md-0">
          <DateSearchPicker
            class="mr-md-2 mr-0"
            v-model="query"
            startedPlaceholder='開始時間'
            endedPlaceholder="結束時間"
            @input="handleDateChange"
          />
          <b-input-group class="">
            <b-form-input v-model="query.keyword" placeholder="搜尋"></b-form-input>
            <b-input-group-append>
              <b-button @click="fetchBookings"><i class="fa fa-search"></i ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.BOOKING_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="isBookingCenter() ? { name: 'BookingCenterBookingCreate', params: { id: bookingPreset.id }} : { name: 'BookingCreate', params: { id: bookingPreset.id }}"
          >
            <i class="fa fa-plus"></i>
            新增預約
          </b-button>
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="bookings"
                :fields="fields"
              >
                <template #cell()="data">
                  <template v-if="isObject(data.value)">
                    <RouterLink v-if="data.value.type === 'collection_select' && checkPermission([consts.COLLECTION_ITEM_MANAGE])" :to="{ name: 'CollectionItemEdit', params: {id: data.value.collection_id, item_id: data.value.collection_item_id } }">
                      {{ data.value.text }}
                    </RouterLink>
                    <template v-else>{{ data.value.text }}</template>
                  </template>
                  <template v-else>{{ data.value }}</template>
                </template>
                <template #cell(date)="data">
                  <span v-html="displayDate(data.item)"></span>
                </template>
                <template #cell(customer)="data">
                  <b-avatar
                    v-if="data.item.customer && data.item.customer.avatar_url"
                    class="mx-1"
                    size="2em"
                    :src="data.item.customer.avatar_url"
                  ></b-avatar>
                  <span v-if="!data.item.customer" class="text-muted">無會員</span>
                  <span v-else>{{ displayCustomer(data.item.customer) }}</span>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_EDIT])"
                    variant="inverse-warning"
                    :to="isBookingCenter() ? { name: 'BookingCenterBookingEdit', params: { id: bookingPreset.id, booking_id: data.item.id }} : { name: 'BookingEdit', params: { id: bookingPreset.id, booking_id: data.item.id }}"
                  >
                    <span class="mdi mdi-lead-pencil"></span> 編輯
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_DELETE])"
                    variant="inverse-danger"
                    @click="deleteBooking(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="query.page"
            :total-rows="totalRows"
            :per-page="query.per_page"
            align="center"
            @change="fetchBookings"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>

</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from '@/utils/PermissionChecker';
import { format } from "date-fns";
import bookingApi from "@/apis/booking";
import collectionApi from "@/apis/collection";
import { mapState } from "vuex";
import DateSearchPicker from "@/components/Page/Dashboard/DateSearchPicker.vue";
import moment from "moment";

export default {
  components: {DateSearchPicker},
  data() {
    return {
      consts: consts,
      showLoading: false,
      fieldsInit: false,
      fields: [
        {
          key: 'date',
          label: '起迄時間',
        },
        {
          key: 'customer',
          label: '預約會員',
        },
        {
          key: 'created_at',
          label: '建立時間',
          sortable: true,
          formatter: (value) => {
            return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
          },
        },
        { key: 'actions', label: '管理' },
      ],
      bookings: [],
      bookingPreset: {
        id: null,
        name: null,
        config: null,
      },
      totalRows: 0,
      collections: [],
      query: {
        start_at: null,
        end_at: null,
        per_page: 20,
        page: 1,
        keyword: '',
      },
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.bookingPreset.id = this.$route.params.id;
    if (!this.bookingPreset.id) {
      this.$swal("錯誤", "找不到預約設定檔", "error");
      return;
    }
    this.fetchBookingPreset();
  },
  methods: {
    isBookingCenter() {
      return this.$route.name.includes("BookingCenter");
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    displayCustomer(customer) {
      if (!customer) {
        return "無會員";
      }
      return customer.name + (customer.mobile_phone ? ` ${customer.mobile_phone}` : '');
    },
    displayDate(item) {
      // 檢查 start_at 和 end_at 並轉換為 Date 對象
      const startAt = item.start_at ? new Date(item.start_at) : null;
      const endAt = item.end_at ? new Date(item.end_at) : null;

      // 格式化日期為 "YYYY-MM-DD HH:mm"
      const formatDateTime = (date) => {
        if (!date) return ''; // 如果日期為 null，返回空字串

        return date.toLocaleString('zh-TW', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        });
      };

      const formattedStartAt = startAt ? formatDateTime(startAt) : ''; // 格式化 start_at
      const formattedEndAt = endAt ? formatDateTime(endAt) : '';       // 格式化 end_at

      // 返回格式化后的日期，使用 <br> 換行
      return `${formattedStartAt} ${formattedStartAt && formattedEndAt ? "~<br>" : ""} ${formattedEndAt}`;
    },
    async fetchBookingPreset () {
      try {
        this.showLoading = true;
        const response = await bookingApi.getBookingPreset(this.bookingPreset.id);
        this.bookingPreset = response.data.data;

        if (!this.bookingPreset.config?.fields || this.bookingPreset.config.fields.length === 0) {
          this.$swal("提醒", "此預約尚未設定欄位，須設定欄位後才能新增及編輯預約", "warning");
          this.$router.push({
            name: "BookingPresetList",
          });
          return;
        }

        let newField = [];

        for (let i = 0; i < this.bookingPreset.config.fields.length; i++) {
          const field = this.bookingPreset.config.fields[i];

          if (field.config?.list_hidden === true) {
            continue;
          }

          let fieldConfig = {
            key: field._id ?? field.config._id,
            label: field.config.title,
            sortable: true,
          };

          if (field.type === "collection_select" && !this.collections.find((collection) => collection.id === field.config.collection_id)) {
            let collectionResponse = await collectionApi.getCollection(field.config.collection_id);
            this.collections.push(collectionResponse.data.data);
          }

          newField.push(fieldConfig);
        }

        this.fields.unshift(...newField);

        await this.fetchBookings();
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取設定錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBookings () {
      try {
        this.showLoading = true;
        const params = {...this.query};
        const response = await bookingApi.getBookings(this.bookingPreset.id, params);

        this.bookings = response.data.data.map((booking) => {
          let newBooking = {
            ...booking
          };
          for (let i = 0; i < booking.data.length; i ++) {
            let data = booking.data[i];

            // 如果遇到陣列型的資料，將陣列轉為字串
            if (Array.isArray(data.value)) {
              newBooking[data.field_id] = data.value.join(', ');
              continue;
            }

            // 如果遇到 collection_select，就把 collection_items 的值取出來
            if (typeof data.value === 'object' && data.value !== null && data.value.type === 'collection_select') {
              const collection = this.collections.find((collection) => collection.id === data.value.collection_id);

              if (!collection) {
                continue;
              }

              const collectionItem = collection.items.find((item) => item.id === data.value.collection_item_id);

              const mainDisplayField = collection.config.fields.find((field) => field.main_display);
              if (! mainDisplayField) {
                // data.value = collectionItem.data[0].value;
                data.value = {
                  ...data.value,
                  text: collectionItem.data[0].value,
                }
              } else {
                let mainDisplayData = collectionItem.data.find((data) => data.field === mainDisplayField.id).value;
                if (mainDisplayData) {
                  // data.value = mainDisplayData;
                  data.value = {
                    ...data.value,
                    text: mainDisplayData,
                  }
                }
              }
            }

            newBooking[data.field_id] = data.value;
          }

          return newBooking;
        });

        this.totalRows = response.data.data.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料集項目錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async deleteBooking(booking) {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          title: "刪除預約",
          text: `是否要將此預約刪除？`,
          type: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "移除",
          reverseButtons: true,
        })

        if (! result.value) {
          return
        }

        await bookingApi.deleteBooking(this.bookingPreset.id, booking.id);
        this.$swal('刪除成功', '', 'success');
        await this.fetchBookings();
      } catch (error) {
        console.error(error);
        this.$swal('刪除失敗', '', 'error');
      } finally {
        this.showLoading = false;
      }
    },
    isObject(field) {
      return typeof field === 'object' && field !== null;
    },
    handleDateChange(date) {
      this.query.start_at = (date.start_at) ? moment(date.start_at).format("YYYY-MM-DD 00:00:00") : null;
      this.query.end_at = (date.end_at) ? moment(date.end_at).format("YYYY-MM-DD 23:59:59") : null;
    },
  }
}
</script>
<style scoped>
.row-searchbar {
  width: 45%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
</style>
